import request from '../utils/request'

export function getServers() {
  return request({
    url: '/servers/list',
    method: 'get'
  })
}

export function deleteServers(data) {
  return request({
    url: '/servers/delete',
    method: 'post',
    data
  })
}

export function editServers(data) {
  return request({
    url: '/servers/edit',
    method: 'post',
    data
  })
}

export function addServers(data) {
  return request({
    url: '/servers/add',
    method: 'post',
    data
  })
}

export function renewServers(data) {
  return request({
    url: '/servers/renew',
    method: 'post',
    data
  })
}