

const columnsIndex = [
  {
    label: 'Id',
    field: 'id',
    renderType: 'Input',
    hidden: false,
    visible: false,
    writable: true,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: 'Rule',
    field: 'rule',
    renderType: 'Select',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
    keyValueField: 'rule',
    options: ["USER-AGENT", "DOMAIN", "DOMAIN-SUFFIX", "DOMAIN-KEYWORD", "DST-PORT", "IP-CIDR", "GEOIP", "FINAL"],
  },
  {
    label: 'Keyword',
    field: 'keyword',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: 'Adapter',
    field: 'adapter',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: 'Note',
    field: 'note',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: 'Status',
    field: 'status',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: false,
  },
  {
    label: 'Deleted',
    field: 'deleted',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: false,
  },
  {
    label: 'CreatedBy',
    field: 'created_by',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: false,
  },
  {
    label: 'UpdatedBy',
    field: 'updated_by',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: false,
  },
  {
    label: 'CreatedAt',
    field: 'created_at',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: false,
  },
  {
    label: 'UpdatedAt',
    field: 'updated_at',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: false,
  },
];

export default columnsIndex;