import request from '../utils/request'

export function getNodes() {
  return request({
    url: '/nodes/list',
    method: 'get'
  })
}

export function deleteNodes(data) {
  return request({
    url: '/nodes/delete',
    method: 'post',
    data
  })
}

export function editNodes(data) {
  return request({
    url: '/nodes/edit',
    method: 'post',
    data
  })
}

export function addNodes(data) {
  return request({
    url: '/nodes/add',
    method: 'post',
    data
  })
}