import Dashboard from '../pages/dashboard';
import Contact from '../pages/contact';
import Shop from '../pages/shop';
import Order from '../pages/order';
import Team from '../pages/team';
import User from '../pages/user';
import UserGroup from '../pages/usergroup';
import Provider from '../pages/provider';
import Node from '../pages/node';
import Server from '../pages/server';
import Rules from '../pages/rules';
import Permissions from '../pages/permissions';
import Profile from '../pages/profile';

const routeModules = [
    { path: "/dashboard", component: <Dashboard />, roles: ["admin", "dev"] },
    { path: "/contact", component: <Contact />, roles: ["admin", "dev"] },
    { path: "/shop", component: <Shop />, roles: ["admin", "dev"] },
    { path: "/order", component: <Order />, roles: ["admin", "dev"] },
    { path: "/team", component: <Team />, roles: ["admin", "dev"] },
    { path: "/user", component: <User />, roles: ["admin", "dev"] },
    { path: "/user/group", component: <UserGroup />, roles: ["admin", "dev"] },
    { path: "/provider", component: <Provider />, roles: ["admin", "dev"] },
    { path: "/node", component: <Node />, roles: ["admin", "dev"] },
    { path: "/server", component: <Server />, roles: ["admin", "dev"] },
    { path: "/rules", component: <Rules />, roles: ["admin", "dev"] },
    { path: "/permissions", component: <Permissions />, roles: ["admin", "dev"] },
    { path: "/profile", component: <Profile />, roles: ["admin", "dev"] },
];

export default routeModules;