const columnsIndex = [
  {
    label: '编号',
    field: 'id',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '名称',
    field: 'name',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '供应商',
    field: 'provider',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '城市',
    field: 'city',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '州',
    field: 'province',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '国家',
    field: 'country',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
];

export default columnsIndex;