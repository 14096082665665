import React from "react";
import { connect } from "react-redux";
import { logout } from "../../../store/actions";
import { useLocation } from "react-router-dom";
import LogoutIcon from '@mui/icons-material/Logout';
import "./index.css";

const Header = ({ user, logout }) => {
  const location = useLocation();
  const { pathname } = location;
  return (
    <>
      <div className="layout-header-name">{pathname}</div>
      <div className="layout-header-right-container">
        <div className="user-profile">
          <i className="avatar">{user.username.substr(0, 1).toUpperCase()}</i>
          <span className="user-info">{user.username}</span>
        </div>
        <button
          className="icon-logout"
          onClick={() => {
            logout();
          }}
        >
          <LogoutIcon />
        </button>
      </div>
    </>
  );
};

export default connect((state) => state.auth, { logout })(Header);
