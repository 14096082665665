import React from 'react';
import { Icon } from '@iconify/react';
import searchFill from '@iconify/icons-eva/search-fill';
import trash2Fill from '@iconify/icons-eva/trash-2-fill';
import { styled } from '@mui/material/styles';
import { Box, Toolbar, Tooltip, IconButton, Typography, OutlinedInput, InputAdornment } from '@mui/material';

const RootStyle = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3)
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
    width: 240,
    transition: theme.transitions.create(['box-shadow', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter
    }),
    '&.Mui-focused': { width: 320, boxShadow: '0 8px 16px 0 rgba(145, 158, 171, .24)' },
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `rgba(145, 158, 171, .32) !important`
    }
}));

const CustomToolbar = ({ selected, value, onChange, placeholder }) => {
    return (
        <RootStyle
            sx={{
                ...(selected > 0 && {
                    color: 'primary.main',
                    bgcolor: 'primary.lighter'
                })
            }}
        >
            {selected > 0 ? (
                <Typography component="div" variant="subtitle1">
                    {selected} selected
                </Typography>
            ) : (
                <SearchStyle
                    value={value}
                    onChange={onChange}
                    onBlur={onChange}
                    placeholder={placeholder}
                    startAdornment={
                        <InputAdornment position="start">
                            <Box component={Icon} icon={searchFill} sx={{ color: 'text.disabled' }} />
                        </InputAdornment>
                    }
                />
            )}
            {selected > 0 ? (
                <Tooltip title="Delete">
                    <IconButton>
                        <Icon icon={trash2Fill} />
                    </IconButton>
                </Tooltip>
            ) : ""}
        </RootStyle>
    );
};

export default CustomToolbar;
