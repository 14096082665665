import React, { useState, useEffect } from 'react';
import { connect } from "react-redux";
import { logout } from "../../../store/actions";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TableRowsIcon from '@mui/icons-material/TableRows';
import logo from '../../../assets/leaf-96px.png';
import MenuItem from './MenuItem';
import menuModules from '../../../config/menuList';
import './index.css';

const Sider = ({user, logout}) => {
    const [inactive, setInactive] = useState(false);

    const onChangeLayoutClass = (inactive) => {
        inactive ? 
        document.getElementById('layout-sider').classList.add('inactive') : 
        document.getElementById('layout-sider').classList.remove('inactive');
        inactive ? 
        document.getElementById('layout-container').classList.add('inactive') : 
        document.getElementById('layout-container').classList.remove('inactive');
    }

    const removeActiveClassFromSubMenu = () => {
        document.querySelectorAll('.sub-menu').forEach(el => {
            el.classList.remove('active');
        });
    };

    useEffect(() => {
        if (inactive) {
            removeActiveClassFromSubMenu();
        }
        onChangeLayoutClass(inactive);
    }, [inactive]);

    useEffect(() => {
        let menuItems = document.querySelectorAll(".sider-nav-item");
        menuItems.forEach((el) => {
            el.addEventListener("click", (e) => {
                removeActiveClassFromSubMenu();
                menuItems.forEach((el) => el.classList.remove('active'));
                el.classList.toggle("active");
            });
        });
        onChangeLayoutClass();
    }, []);

    return (
        <div>
            <div className='top-section'>
                <a href="/" className="logo">
                        <img src={logo} alt="reber" />
                        <span>reber</span>
                </a>
                <i onClick={() => setInactive(!inactive)} className="toggle-menu-btn">{inactive ? <TableRowsIcon /> : <ArrowBackIcon />}</i>
            </div>

            <div className="search-controller">
                <i className="fa-solid fa-search search-icon"></i>
                <input type="text" placeholder='search' />
            </div>

            <div className="divider"></div>

            <div className="sider-nav">
                <ul>
                    {
                        menuModules.map((v, k) => (
                            <MenuItem
                                key={k}
                                name={v.name}
                                icon={v.icon}
                                to={v.to}
                                subMenus={v.subMenus || []}
    
                            />
                        ))
                    }
                </ul>
            </div>
        </div>
    )
}

export default connect((state) => state.auth, { logout })(Sider);