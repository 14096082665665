const columnsIndex = [
  {
    label: '编号',
    field: 'id',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '名称',
    field: 'name',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '域名',
    field: 'host',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '端口',
    field: 'port',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '节点',
    field: 'ip',
    renderType: 'Select',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
    keyValueField: 'public_address',
    sourceApi: {url: '/nodes/lite', method: 'get'},
  },
  {
    label: '城市',
    field: 'city',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '国家',
    field: 'country',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: 'Mode',
    field: 'mode',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: 'Adapter',
    field: 'adapter',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: 'Final',
    field: 'final',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '标签',
    field: 'tag',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
];

export default columnsIndex;