import request from '../utils/request'

export function getRulesList() {
    return request({
        url: '/rules/list',
        method: 'get'
    })
}

export function getRuleWithQuery(data) {
    return request({
        url: '/rules/get',
        method: 'post',
        data
    })
}

export function deleteRules(data) {
    return request({
        url: '/rules/delete',
        method: 'post',
        data
    })
}

export function editRules(data) {
    return request({
        url: '/rules/edit',
        method: 'post',
        data
    })
}

export function addRules(data) {
    return request({
        url: '/rules/add',
        method: 'post',
        data
    })
}

export function syncRules() {
    return request({
        url: '/rules/sync',
        method: 'get'
    })
}