const columnsIndex = [
  {
    label: '编号',
    field: 'id',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '订单类型',
    field: 'order_type',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '单价',
    field: 'order_price',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '数量',
    field: 'order_count',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '总价',
    field: 'order_total',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '实付',
    field: 'order_paid',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '支付方式',
    field: 'payment_method',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: 'Before',
    field: 'before_time',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: 'After',
    field: 'after_time',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '备注',
    field: 'note',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '用户',
    field: 'username',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '状态',
    field: 'status',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '创建时间',
    field: 'created_at',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: true,
    updateVisible: true,
  },
];

export default columnsIndex;