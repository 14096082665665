import request from '../utils/request'

export function getProviders() {
  return request({
    url: '/providers/list',
    method: 'get'
  })
}

export function deleteProviders(data) {
  return request({
    url: '/providers/delete',
    method: 'post',
    data
  })
}

export function editProviders(data) {
  return request({
    url: '/providers/edit',
    method: 'post',
    data
  })
}

export function addProviders(data) {
  return request({
    url: '/providers/add',
    method: 'post',
    data
  })
}