import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Stack,
} from '@mui/material';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { CustomDownloadTool, CustomQrCode, CustomEditTool, CustomDeleteTool, CustomCreateTool, CustomViewTool, CustomAlertTool, CustomTool } from '../../components/toolbar';
import CustomPassword from '../../components/password';
import CustomTable from '../../components/table';
import { addTeam, getTeams, editTeam, teamEditPassword, deleteTeam, teamResetSecret, teamResetSubscribe } from '../../api/team';
import request from '../../utils/request';
import columnsIndex from '../../components/dataIndex/subusers';

const Team = () => {
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState([]);
  const [error, setError] = useState("");
  const [resource, setResource] = useState({});
  const [visible, setVisible] = useState(false);

  const onEdit = (record) => {
    editTeam({ ...record }).then((response) => {
      const { code } = response;
      if (code === 200) {
        setError("success");
        setDataSource(pre => {
          return pre.map(item => {
            if (item?.id === record?.id) {
              return record
            }
            return item
          })
        })
      } else {
        setError("error");
      }
      setVisible(true);
    })
  };

  const onEditPassword = (record) => {
    teamEditPassword({ ...record }).then((response) => {
      const { code } = response;
      if (code === 200) {
        setError("success");
      } else {
        setError("error");
      }
      setVisible(true);
    })
  }

  const onDelete = (record) => {
    const _data = dataSource.filter(item => item.id !== record.id);
    deleteTeam({ ...record }).then((response) => {
      const { code } = response;
      if (code === 200) {
        setError("success");
        setDataSource(_data);
      } else {
        setError("error");
      }
      setVisible(true);
    })
  };

  const onCreate = (record) => {
    console.log("onCreate: ", record);
    addTeam({ ...record }).then((response) => {
      const { code } = response;
      if (code === 200) {
        setError("success");
      } else {
        setError("error");
      }
    })
  }

  const onResetSecret = (record) => {
    teamResetSecret({ ...record }).then((response) => {
      const { code } = response;
      if (code === 200) {
        setError("success");
      } else {
        setError("error");
      }
      setVisible(true);
    })
  }

  const onResetSubscribeCode = (record) => {
    teamResetSubscribe({ ...record }).then((response) => {
      const { code } = response;
      if (code === 200) {
        setError("success");
      } else {
        setError("error");
      }
      setVisible(true);
    })
  }

  const qrcode = (code, username, expires) => {
    return "https://rebercloud.com/qrcode?v=" + window.btoa("https://rebercloud.com/sub/team/subscribe/" + code + "#REBER CLOUD | " + username + " | Expires " + expires);
  }

  const subscribe = (code) => {
    return "https://rebercloud.com/sub/team/subscribe/" + code;
  }

  const columns = [
    ...columnsIndex,
    {
      field: 'action',
      label: 'Action',
      renderType: 'render',
      hidden: false,
      visible: false,
      require: false,
      writable: false,
      updateWritable: false,
      updateVisible: false,
      render: (record) => {
        return (
          <Stack direction="row">
            <CustomQrCode url={qrcode(record.subscribe_code, record.username, record.expires)} okText='OK' />
            <CustomDownloadTool url={subscribe(record.subscribe_code)} />
            <CustomViewTool columns={columnsIndex} dataSource={record} title='View Team' />
            <CustomPassword dataSource={record} onOk={onEditPassword} title='New Password' />
            <CustomEditTool columns={columnsIndex} resource={resource} dataSource={record} onOk={onEdit} title='Edit User' />
            <CustomTool ICON={RestartAltIcon} dataSource={record} onOk={onResetSecret} title='Reset Secret' Text='Confirm to reset secret.' />
            <CustomTool ICON={RestartAltIcon} dataSource={record} onOk={onResetSubscribeCode} title='Reset Subscribe Code' Text='Confirm to reset subscribe code.' />
            <CustomDeleteTool dataSource={record} onOk={onDelete} title='Delete' />
          </Stack>
        )
      }
    },
  ];

  const fetchData = () => {
    getTeams().then((response) => {
      const { code } = response;
      if (code === 200) {
        const { data } = response;
        setDataSource(data);
      }
    })
    columnsIndex?.forEach(item => {
      if (item?.renderType === 'Select') {
        item?.['sourceApi'] && request({ ...item?.['sourceApi'] }).then((response) => {
          const { code } = response;
          if (code === 200) {
            const { data } = response;
            setResource(pre => { return { ...pre, [item?.['field']]: data } });
          }
        })
      }
    })
  }
  useEffect(() => {
    fetchData();
    return () => {
      setResource({});
      setDataSource({});
    };
  }, []);

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          User
        </Typography>
        <CustomCreateTool columns={columnsIndex} resource={resource} dataSource={data} onOk={onCreate} title='New Team' text='New Team' />
      </Stack>
      <CustomTable rows={dataSource} columns={columns} rowKey='field' query='username' placeholder='Search user...' />
      <CustomAlertTool visible={visible} setVisible={setVisible} onError={error} />
    </Container>
  )
};

export default Team;
