import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Stack,
} from '@mui/material';
import { CustomViewTool, CustomEditTool, CustomDeleteTool, CustomCreateTool } from '../../components/toolbar';
import CustomTable from '../../components/table';
import { getProviders, editProviders, addProviders } from '../../api/provider';
import columnsIndex from '../../components/dataIndex/providerinfo';

const Provider = () => {
  const [dataSource, setDataSource] = useState([]);
  const [data, setData] = useState({});
  const [visible, setVisible] = useState(false);

  const onEdit = (record) => {
    console.log(record);
    editProviders({ ...record }).then((response) => {
      console.log(response);
      setVisible(true);
      setDataSource(pre => {
        return pre.map(item => {
          if (item?.id === record?.id) {
            return record
          }
          return item
        })
      })
    })
  };

  const onDelete = (record) => {
    const _data = dataSource.filter(item => item.id !== record.id);
    setDataSource(_data);
  };

  const onCreate = (record) => {
    console.log("onCreate", record);
    addProviders({ ...record }).then((response) => {
      console.log(response);
      setVisible(true);
      setDataSource(pre => {
        return [{ ...record }, ...pre]
      })
    })
  }

  const columns = [
    ...columnsIndex,
    {
      field: 'action',
      label: 'Action',
      renderType: 'render',
      hidden: false,
      visible: false,
      require: false,
      writable: false,
      updateWritable: false,
      updateVisible: false,
      render: (record) => {
        return (
          <Stack direction="row">
            <CustomViewTool columns={columnsIndex} dataSource={record} title='View Provider' />
            <CustomEditTool columns={columnsIndex} dataSource={record} onOk={onEdit} title='Edit Provider' />
            <CustomDeleteTool dataSource={record} onOk={onDelete} title='Delete' />
          </Stack>
        )
      }
    },
  ];

  const fetchData = () => {
    getProviders().then((data) => {
      setDataSource(data.data);
    })
  }
  useEffect(() => {
    fetchData();

    return () => {
      setDataSource({});
    };
  }, []);

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Provider
        </Typography>
        <CustomCreateTool columns={columnsIndex} dataSource={data} onOk={onCreate} title='New Provider' text='New Provider' />
      </Stack>
      <CustomTable rows={dataSource} columns={columns} rowKey='field' query='name' placeholder='Search provider...' />
    </Container>
  )
};

export default Provider;
