import request from '../utils/request'

export function getPermissions() {
  return request({
    url: '/permissions/list',
    method: 'get'
  })
}

export function deletePermissions(data) {
  return request({
    url: '/permissions/delete',
    method: 'post',
    data
  })
}

export function editPermissions(data) {
  return request({
    url: '/permissions/edit',
    method: 'post',
    data
  })
}

export function addPermissions(data) {
  return request({
    url: '/permissions/add',
    method: 'post',
    data
  })
}