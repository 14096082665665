import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { connect } from "react-redux";
import Header from './header';
import Sider from './sider';
import Content from './content';
import './index.css';

const Layout = ({ isAuthenticated }) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate("/login");
    }
  }, [isAuthenticated])

  return (
    <div>
      <div id='layout-sider' className='layout-sider dark'>
        <Sider />
      </div>
      <div id="layout-container" className='layout-container'>
        <div id="layout-header" className="layout-header">
          <Header />
        </div>
        <div id="layout-content" className="layout-content">
          <Content />
        </div>
      </div>
    </div>
  );
};

export default connect((state) => state.auth)(Layout);