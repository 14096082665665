import request from '../utils/request'

export function reqTeamUserInfo(data) {
  return request({
    url: '/team',
    method: 'post',
    data
  })
}

export function getTeams() {
  return request({
    url: '/teams/list',
    method: 'get'
  })
}

export function deleteTeam(data) {
  return request({
    url: '/teams/delete',
    method: 'post',
    data
  })
}

export function editTeam(data) {
  return request({
    url: '/teams/edit',
    method: 'post',
    data
  })
}

export function addTeam(data) {
  return request({
    url: '/teams/add',
    method: 'post',
    data
  })
}

export function teamEditPassword(data) {
  return request({
    url: '/teams/edit/password',
    method: 'post',
    data: data
  })
}

export function temEditServer(data) {
  return request({
    url: '/teams/edit/server',
    method: 'post',
    data
  })
}

export function teamResetSecret(data) {
  return request({
    url: '/teams/reset/secret',
    method: 'post',
    data: data
  })
}

export function teamResetSubscribe(data) {
  return request({
    url: '/teams/reset/subscribe',
    method: 'post',
    data
  })
}