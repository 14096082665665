const columnsIndex = [
  {
    label: '编号',
    field: 'id',
    renderType: 'Input',
    hidden: true,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '区域',
    field: 'region',
    renderType: 'Select',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
    keyValueField: 'name',
    sourceApi: {url: '/providers/lite', method: 'get'},
  },
  {
    label: '供应商',
    field: 'provider',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: 'IP地址',
    field: 'public_address',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '私有网络',
    field: 'private_network',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '私有地址',
    field: 'private_address',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '城市',
    field: 'city',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '国家',
    field: 'country',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: false,
    updateWritable: false,
    updateVisible: true,
  },
];

export default columnsIndex;