const columnsIndex = [
  {
    label: '编号',
    field: 'id',
    renderType: 'Input',
    hidden: false,
    visible: false,
    writable: true,
    updateWritable: false,
    updateVisible: true,
  },
  {
    label: '名称',
    field: 'name',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: 'Slug',
    field: 'slug',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '内容',
    field: 'content',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
  {
    label: '注释',
    field: 'description',
    renderType: 'Input',
    hidden: false,
    visible: true,
    writable: true,
    updateWritable: true,
    updateVisible: true,
  },
];

export default columnsIndex;